<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="primary" size="large">
        <ion-icon name="chevron-back-outline">
        </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" color="primary">
      {{_lead?.first_name}} {{_lead?.last_name}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="arrow-down" color="medium" (click)="presentPopover($event)" size="large">
      </ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <div *ngIf="_selectedButton === 'notes'" class="animate__animated animate__slideInRight animate__faster"
          #notesarea>
          <ion-item-group>
            <ion-item-divider class="ion-padding-vertical">
              <ion-button slot="start" shape="round" fill="outline" expand="full" color="danger" (click)="changeTab('close')">Close<ion-icon name="close-outline" slot="start" color="danger"></ion-icon></ion-button>
              <ion-button slot="end" shape="round" fill="outline" expand="full" color="primary" (click)="call()">Call<ion-icon name="call-outline" slot="start" color="primary"></ion-icon></ion-button>
            </ion-item-divider>
            <ion-item-divider class="ion-padding-vertical">
              <ion-label color="primary" slot="start">Follow-Up</ion-label>
                <ion-chip color="medium" slot="end" id="follow-up">
                  <span *ngIf="_lead.scheduled == null; else fudate">
                    click to set
                  </span>
                  <ng-template #fudate>
                    {{_lead.scheduled | date:'fullDate'}}
                  </ng-template>
                </ion-chip>
            </ion-item-divider>
            <ion-popover side="bottom" alignment="end" trigger="follow-up" [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime [ngModel]="formattedDate(_lead.scheduled)" max="2026" min="2021"
                  (ionChange)="updateScheduled($event)" [showDefaultButtons]="true" presentation="date">
                </ion-datetime>
              </ng-template>
            </ion-popover>
            <ion-item-divider class="ion-padding-vertical">
              <ion-label color="primary" slot="start">Quality</ion-label>
              <ion-item lines="none" slot="end">
                  <bar-rating [rate]="_lead.probability"  [max]="5" (rateChange)="onRateChange($event)" 
                   showText [titles]="['', 'Bad', 'Not Likely', 'Possible' , 'Pretty Good', 'Awesome']" slot="end">
                  </bar-rating>
              </ion-item>
            </ion-item-divider>
            <ion-item-divider class="ion-padding-vertical">
              <ion-label color="primary" slot="start">Sale Date</ion-label>
                <ion-chip color="medium" id="effectiveDate" slot="end">
                  <span *ngIf="_lead.expected_close_date == null; else effdate">
                    click to set
                  </span>
                  <ng-template #effdate>
                    {{_lead.expected_close_date | date:'MMMM YYYY'}}
                  </ng-template>
                </ion-chip>
            </ion-item-divider>
            <ion-popover side="bottom" alignment="end" [keepContentsMounted]="true" trigger="effectiveDate">
              <ng-template>
                <ion-datetime [ngModel]="formattedDate(_lead.expected_close_date)" max="2026" min="2021"
                  (ionChange)="updateProjectedClose($event)" [showDefaultButtons]="true" presentation="month-year">
                </ion-datetime>
              </ng-template>
            </ion-popover>
            <ion-item-divider class="ion-padding-vertical">
              <ion-label color="primary" slot="start">
                Notes
              </ion-label>
            </ion-item-divider>
            <ion-item lines="none" *ngIf="!_addNote && !_editNotes"
              class="animate__animated animate__slideInUp animate__faster">
              <ion-icon name="pencil-outline" color="medium" slot="start" (click)="editNotes()"></ion-icon>
              <ion-icon name="add-outline" color="success" slot="end" (click)="addNote()"></ion-icon>
            </ion-item>
            <ion-item lines="none" *ngIf="_editNotes" class="animate__animated animate__slideInDown animate__faster">
              <ion-button fill="clear" slot="end" expand="block" color="medium" (click)="cancelNotesEdit()">
                Cancel
              </ion-button>
              <ion-button fill="clear" slot="end" expand="block" (click)="updateNotes()">
                <ion-icon name="save-outline" slot="end" color="success"></ion-icon>
                Save
              </ion-button>
            </ion-item>
            <ion-item *ngIf="_addNote" class="animate__animated animate__slideInDown animate__faster">
              <ion-input inputmode="text" [(ngModel)]="_newNote" spellcheck="true" #newnote wrap="hard"
                placeholder="add note...">

              </ion-input>
              <ion-icon name="save-outline" color="success" slot="end" (click)="saveNote()"></ion-icon>
              <ion-icon name="close-outline" color="danger" slot="end" (click)="cancelNotesEdit()"></ion-icon>
            </ion-item>
            <ion-textarea inputmode="text" #editnote label="notes" disabled="{{!_editNotes}}" autoGrow="false"
              spellcheck="true" wrap="hard" placeholder="--no notes entered--" [(ngModel)]="_notes" rows="13"
              autocapitalize="true">
            </ion-textarea>
          </ion-item-group>
        </div>
        <div *ngIf="_selectedButton === 'history'" class="animate__animated animate__slideInRight animate__faster"
          id="historyarea">
          <div *ngFor="let conhistory of _lead?.conversation?.history">
            <div *ngIf="conhistory.type === 'CALL'">
              <ion-item>
                <ion-icon slot="start" color="medium" name="call-outline"></ion-icon>
                <ion-icon color="success" name="arrow-back-outline" *ngIf="conhistory.direction === 'IN'">
                </ion-icon>
                <ion-icon color="medium" name="arrow-forward-outline" *ngIf="conhistory.direction !== 'IN'">
                </ion-icon>
                <ion-label>
                  <p>
                    <span>{{conhistory.date_created | date:'M/dd/YY h:mma' }} </span>
                    <span *ngIf="+conhistory.detail === +conhistory.detail && conhistory.detail != null">
                      -
                      {{+conhistory.detail < 60 ? conhistory.detail.toString().concat(' sec') : (+conhistory.detail / 60).toFixed(0).toString().concat(' min ', (+conhistory.detail % 60).toString(), ' sec')}}
                    </span><br />
                    <span>{{conhistory.user_name == null ? 'unanswered' : conhistory.user_name }} >>
                      {{conhistory.status}}</span>
                  </p>
                  <audio id="{{conhistory.id}}">
                    <source src="{{conhistory.recordingurl}}">
                  </audio>
                </ion-label>
                <button (click)="playvm(conhistory.id)" style="background-color: #ffffff;" id="play-{{conhistory.id}}"
                  *ngIf="conhistory.recordingurl != undefined">
                  <ion-icon slot="icon-only" name="play-outline" color="success" size="large"></ion-icon>
                </button>
                <button (click)="pausevm()" style="background-color: #ffffff;" id="pause-{{conhistory.id}}"
                  hidden="true">
                  <ion-icon slot="icon-only" name="pause-outline" color="primary" size="large"></ion-icon>
                </button>
                <a href="{{conhistory.recordingurl}}" download="{{conhistory.id}}" target="_blank"
                  style="background-color: #ffffff;" *ngIf="conhistory.recordingurl != undefined">
                  <ion-icon slot="icon-only" name="download" color="medium" size="large"></ion-icon>
                </a>
              </ion-item>
            </div>
            <div *ngIf="conhistory.type === 'VOICEMAIL'">
              <ion-item>
                <ion-icon slot="start" color="medium" name="recording-outline"></ion-icon>
                <ion-icon color="success" name="arrow-back-outline" *ngIf="conhistory.direction === 'IN'">
                </ion-icon>
                <ion-icon color="medium" name="arrow-forward-outline" *ngIf="conhistory.direction !== 'IN'">
                </ion-icon>
                <ion-label>
                  <span>{{conhistory?.date_created | date:'M/dd/YY h:mma' }} -</span>
                  <audio id="{{conhistory.id}}">
                    <source src="{{conhistory.detail}}">
                  </audio>
                </ion-label>
                <button (click)="playvm(conhistory.id)" style="background-color: #ffffff;">
                  <ion-icon slot="icon-only" name="play-outline" color="success" size="large"></ion-icon>
                </button>
              </ion-item>
            </div>
            <div *ngIf="conhistory.type === 'MESSAGE'">
              <ion-item>
                <ion-icon slot="start" color="medium" name="chatbubbles-outline"></ion-icon>
                <ion-icon color="success" name="arrow-back-outline" *ngIf="conhistory.direction === 'IN'">
                </ion-icon>
                <ion-icon color="medium" name="arrow-forward-outline" *ngIf="conhistory.direction !== 'IN'">
                </ion-icon>
                <ion-label>
                  <span>{{conhistory?.date_created | date:'M/dd/YY h:mma' }} </span>
                </ion-label>
              </ion-item>
            </div>
          </div>
        </div>
        <div *ngIf="_selectedButton === 'message'"
          class="messages ion-align-items-stretch ion-border-medium animate__animated animate__slideInRight animate__faster"
          id="messagearea">
          <ion-button *ngIf="_lead?.conversation?.messages.length === 0" (click)="introMessage()" fill="clear"
            Expand="block" color="primary" size="large">
            Send Intro Text
            <ion-icon name="send-outline" slot="end"></ion-icon>
          </ion-button>
          <ion-item *ngFor="let message of _lead?.conversation?.messages" lines="none"
            [color]="message.message_direction == 'IN' ? 'light' : ''" class="message-out">
            <ng-container *ngIf="message.message_direction == 'IN'; else elseTemplate">
              <ion-icon slot="start" color="primary" name="chatbubbles-outline"></ion-icon>
              <span class="inmessage">{{message.message_text}}</span>
            </ng-container>
            <ng-template #elseTemplate>
              <span class="ion-text-end">{{message.message_text}}</span>
              <ion-icon slot="end" color="medium" name="chatbubbles-outline"></ion-icon>
            </ng-template>
          </ion-item>
          <ion-item class="messagebar">
            <ion-textarea #messageta label="" autoGrow="true" spellcheck="true" wrap="hard" placeholder="...reply"
              [(ngModel)]="_message">
            </ion-textarea>
            <ion-button fill="clear" color="primary" slot="end" (click)="sendMessage()">
              <ion-icon name="send-outline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-item>
        </div>
        <div *ngIf="_selectedButton === 'close'" class="animate__animated animate__slideInRight animate__faster">
          <ion-list class="ion-no-padding">
            <ion-radio-group [(ngModel)]="closedata.disposition" allowEmptySelection="true">
              <ion-item>
                <ion-radio value="won" slot="start" color="success" labelPlacement="end">Application Submitted</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio value="resolved" slot="start" color="success" labelPlacement="end">Matter Resolved</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio value="lost" slot="start" color="danger" labelPlacement="end">Disqualified</ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <div *ngIf="closedata.disposition !== 'lost'">
            <ion-item lines="none" class="ion-text-center" id="close-date">
              <ion-label slot="start">Date</ion-label>
              <ion-chip color="medium" slot="end">
                <span *ngIf="closedata.scheduled == null; else fudate">
                  click to set
                </span>
                <ng-template #fudate>
                  {{closedata.scheduled | date:'fullDate'}}
                </ng-template>
              </ion-chip>
              <!-- Date<ion-datetime slot="end" pickerFormat="MMM/DD/YYYY" displayFormat="DDDD - MMM DD, YYYY"
                value="{{_lead.scheduled}}" max="2026" min="2021" (ionChange)="updateScheduled($event)"></ion-datetime> -->
            </ion-item>
            <ion-popover side="bottom" alignment="end" trigger="close-date">
              <ng-template>
                <ion-datetime [ngModel]="formattedDate(closedata.scheduled)" max="2026" min="2021"
                  (ionChange)="updatecloseScheduled($event)" [showDefaultButtons]="true" presentation="date">
                </ion-datetime>
              </ng-template>
            </ion-popover>
            <ion-item *ngIf="closedata.disposition == 'won'">
              <ion-label>Carrier</ion-label>
              <ion-select placeholder="Select One" [(ngModel)]="closedata.product">
                <ion-select-option value="aetna">Aetna</ion-select-option>
                <ion-select-option value="banner">Banner</ion-select-option>
                <ion-select-option value="bright-heath">Bright Health</ion-select-option>
                <ion-select-option value="bcbs">Blue Cross Blue Shield</ion-select-option>
                <ion-select-option value="cigna">Cigna</ion-select-option>
                <ion-select-option value="humana">Humana</ion-select-option>
                <ion-select-option value="silver-script">Silver Script</ion-select-option>
                <ion-select-option value="uhc">United Healthcare</ion-select-option>
                <ion-select-option value="wellcare">WellCare</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item *ngIf="closedata.disposition == 'won'">
              <ion-label>PCP Name</ion-label>
              <ion-input [(ngModel)]="closedata.attribute1">
              </ion-input>
            </ion-item>
          </div>
          <div *ngIf="closedata.disposition === 'lost'">
            <ion-item>
              <ion-label>Reason</ion-label>
              <ion-select placeholder="Select One" [(ngModel)]="closedata.reason">
                <ion-select-option value="no-response">No Response</ion-select-option>
                <ion-select-option value="not-eligible">Not Eligible</ion-select-option>
                <ion-select-option value="not-interested">Not Interested</ion-select-option>
                <ion-select-option value="not-a-lead">Not A Lead</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <ion-button fill="clear" expand="block" (click)="closelead()" color="primary" class="ion-margin-top"
            size="large">
            Close <ion-icon name="eye-off-outline" slot="end"></ion-icon>
          </ion-button>
        </div>
        <div *ngIf="_selectedButton === 'edit'" class="animate__animated animate__slideInRight animate__faster">
          <form (ngSubmit)="updateLead()">
            <ion-list lines="full" class="ion-no-margin ion-no-padding">

              <ion-item lines="none">
                <ion-label position="stacked">Attention</ion-label>
                <ion-toggle name="attention" [(ngModel)]="_editlead.attention" color="success"></ion-toggle>
              </ion-item>

              <ion-item-divider lines="none">
                <ion-label color="primary">
                  Contact Data
                </ion-label>
              </ion-item-divider>

              <ion-item lines="none">
                <ion-label position="floating">First Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" [(ngModel)]="_editlead.first_name" name="first_name"></ion-input>
              </ion-item>

              <ion-item lines="none">
                <ion-label position="floating">Last Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" [(ngModel)]="_editlead.last_name" name="last_name"></ion-input>
              </ion-item>

              <ion-item lines="none">
                <ion-label position="floating">Phone Number</ion-label>
                <ion-input type="tel" name="phonenumber" [(ngModel)]="_phone.phonenumber"></ion-input>
              </ion-item>

              <ion-item lines="none">
                <ion-label position="floating">Email</ion-label>
                <ion-input type="email" name="email" [(ngModel)]="_email.email"></ion-input>
              </ion-item>
              <ion-item-divider lines="none">
                <ion-label color="primary">
                  Address
                </ion-label>
              </ion-item-divider>
              <ion-item lines="none">
                <ion-label position="floating">Street</ion-label>
                <ion-input type="street" name="street" [(ngModel)]="_address.street"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="floating">City</ion-label>
                <ion-input type="city" name="city" [(ngModel)]="_address.city"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="floating">State</ion-label>
                <ion-input type="state" name="state" [(ngModel)]="_address.state"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="floating">Zip</ion-label>
                <ion-input type="zip" name="zip" [(ngModel)]="_address.zip"></ion-input>
              </ion-item>
            </ion-list>
            <div class="ion-padding">
              <ion-button expand="block" type="submit" color="primary" fill="clear">
                <ion-icon name="save-outline" slot="start"></ion-icon>Save
              </ion-button>
            </div>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="changeTab('notes')" Expand="full" fill="clear"
          color="{{_selectedButton === 'notes' ? 'secondary' : 'medium'}}"
          [ngClass]="{'button-tab-pressed' : _selectedButton === 'notes'}" class="ion-no-padding">
          <div>
            <ion-icon name="pencil-outline" class="small-padding-bottom"></ion-icon>
            Info
          </div>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button (click)="changeTab('history')" Expand="full" fill="clear"
          color="{{_selectedButton === 'history' ? 'secondary' : 'medium'}}"
          [ngClass]="{'button-tab-pressed' : _selectedButton === 'history'}" class="ion-no-padding">
          <div>
            <ion-icon name="time-outline" class="small-padding-bottom"></ion-icon>
            History
          </div>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button (click)="changeTab('message')" Expand="full" fill="clear"
          color="{{_selectedButton === 'message' ? 'secondary' : 'medium'}}"
          [ngClass]="{'button-tab-pressed' : _selectedButton === 'message'}" class="ion-no-padding">
          <div>
            <ion-icon name="chatbubble-outline" class="small-padding-bottom"></ion-icon>
            Message
          </div>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>