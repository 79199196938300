<ion-list> 
  <ion-list-header color="light"><h3 class="ion-text-center">{{_lead.first_name}} {{_lead.last_name}}</h3></ion-list-header>
  <ion-item button (click)="transferLead()" *ngIf="_isAdmin || _isTrusted || _isPipeline">Transfer<ion-icon name="return-up-back-outline" slot="start" color="success"></ion-icon></ion-item>
  <ion-item button (click)="claimLead()" *ngIf="!_isPipeline">Claim<ion-icon name="pricetag-outline" slot="start" color="success"></ion-icon></ion-item>
  <ion-item button (click)="editLead()" *ngIf="_isAdmin || _isTrusted || _isPipeline">Details<ion-icon name="reader-outline" slot="start" color="medium"></ion-icon></ion-item>
  <!-- 
  <ion-item button (click)="closeLead()" *ngIf="_isAdmin || _isPipeline">Close<ion-icon name="close-outline" slot="start" color="danger"></ion-icon></ion-item>
  <ion-item button (click)="call()">Call<ion-icon name="call-outline" slot="start" color="primary"></ion-icon></ion-item>
  -->
</ion-list>
<ion-button expand="block" (click)="dismissPopover()" fill="clear" color="danger">Cancel</ion-button>